import base from './base';
import axios from '../utils/axios_http';

const Settings = {
  // 获取客服设置
  getCustomerService() {
    return axios.post(`${base.customerService}/queryCustomerService`);
  },
  // 更新客服设置
  updateCustomerService(params) {
    return axios.post(`${base.customerService}/updateCustomerService`, params);
  },
  
  // 限制发货列表查询
  getAddressManagementList(params) {
    return axios.post(`${base.addressLimit}/queryAddressLimitList`, params);
  },
};

export default Settings;