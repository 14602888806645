import base from './base';
import axios from '../utils/axios_http';

const home = {
  // 数据汇总
  getHomeTopDataVO(params) {
    return axios.post(`${base.index}/getHomeTopDataVO`, params);
  },
  // 奶卡销售额曲线图
  getStoreDailyMilkSaleDataList(params) {
    return axios.post(`${base.index}/getStoreDailyMilkSaleDataList`, params);
  },
  // 奶卡销售排行
  getDailyMilkCardSaleCountDataList(params) {
    return axios.post(`${base.index}/getDailyMilkCardSaleCountDataList`, params);
  },
  // 奶卡核销折线图
  getStoreDailyMilkOrderAndVerifyDataList(params) {
    return axios.post(`${base.index}/getStoreDailyMilkOrderAndVerifyDataList`, params);
  },
  // 奶卡核销饼图
  getStoreDailyMilkOrderAndVerifyCountDataVO(params) {
    return axios.post(`${base.index}/getStoreDailyMilkOrderAndVerifyCountDataVO`, params);
  },
};

export default home;