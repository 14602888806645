import axios from "axios";
import this_vue from "../main";
let Base64 = require("js-base64").Base64;

// 创建axios实例
const instance = axios.create({
  timeout: 300000,
});
// 设置post请求头
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.post["jjn-token"] = localStorage.getItem("jjn-token")
  ? Base64.decode(localStorage.getItem("jjn-token"))
  : "";
instance.defaults.headers.get["jjn-token"] = localStorage.getItem("jjn-token")
  ? Base64.decode(localStorage.getItem("jjn-token"))
  : "";

// From Data
// instance.defaults.transformRequest = [function (data) {
//     let src = ''
//     for (let item in data) {
//         src += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
//     }
//     return src
// }]

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (response.data.code == "200000") {
      this_vue.$router.push({ name: "Login" });
    }
    if (response.headers["jjn-token"]) {
      localStorage.setItem(
        "jjn-token",
        Base64.encode(response.headers["jjn-token"])
      );
      instance.defaults.headers.post["jjn-token"] = localStorage.getItem(
        "jjn-token"
      )
        ? Base64.decode(localStorage.getItem("jjn-token"))
        : "";
      instance.defaults.headers.get["jjn-token"] = localStorage.getItem(
        "jjn-token"
      )
        ? Base64.decode(localStorage.getItem("jjn-token"))
        : "";
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
