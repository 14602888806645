const Domain = process.env.VUE_APP_DOMAIN;

const base = {
  base: Domain, // 基础
  cashier: process.env.VUE_APP_SERVER_URL, // 收银后台接口
  User: Domain + "/api/web/user", // 用户信息接口
  milkCardManagement: Domain + "/api/pc/userMilk", // 奶卡管理
  userMilkCardStatistic: Domain + "/api/pc/userMilkCardStatistic", //奶卡对账
  milk: Domain + "/api/pc/milk", // 奶卡设置
  milkCardType: Domain + "/api/pc/milkCardType", // 奶卡类型
  commodity: Domain + "/api/pc/commodity", // 商品管理
  serialNumber: Domain + "/api/pc/serialNumber", // 序列号管理
  order: Domain + "/api/pc/order", // 邮寄订单
  verifySell: Domain + "/api/pc/verifySell", // 核销记录
  customerService: Domain + "/api/pc/customerService", // 客服设置
  addressLimit: Domain + "/api/pc/addressLimit", // 限制发货地址
  uploadImg: Domain + "/api/web/upload/uploadImg", // 图片上传
  popularize: Domain + "/api/pc/popularize", // 地推
  index: Domain + "/api/pc/backHomeDataStatistic", // 首页新数据面板
  distribution: Domain + "/api/pc/backendApi/distribution", // 分销管理
  getGradeAll: Domain + "/api/pc/distributionGrade", // 奶卡会员管理
  milkCardRefund: Domain + "/api/pc/milkCardRefund", // 奶卡会员退款
  goodCategory: Domain + "/api/pc/goodCategory", // 奶卡商品分类管理
  getMilkCardQrCode: Domain + "/api/pc/milk", // 二维码
  payment: Domain + "/api/pc/payment",
};

export default base;
