const common = {
  state: {
    allStoreCategorys: [],
  },

  mutations: {
    SET_ALL_STORE_CATEGORYS: (state, categorys) => {
      state.allStoreCategorys = categorys;
    },
  },

  actions: {},
};

export default common;
