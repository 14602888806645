export default {
    namespaced: 'System',
    state: {
        UserInfo: {},   // 用户信息
        MenuCollapsed: false, // 目录展开收缩的状态
    },
    mutations: {
        //  用户信息
        UserInfoChange(state, params) {
            state.UserInfo = params;
        },
        // 目录展开收缩的状态
        MenuCollapsedChange(state, params) {
            state.MenuCollapsed = params;
        },
    },
    actions: {},
    modules: {}
}