<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

import { User } from "@apis/index";

import { createNamespacedHelpers } from "vuex";
const { mapMutations } = createNamespacedHelpers("System");

export default {
  name: "app",
  data() {
    return {
      zh_CN,
    };
  },
  beforeCreate() {
    User.getUserInfo()
      .then((res) => {
        if (res.data.success) {
          this.UserInfoChange(res.data.data);
        } else {
          this.$router.push({
            name: "Login",
          });
        }
      })
      .catch(() => {
        this.$router.push({
          name: "Login",
        });
      });
  },
  methods: {
    ...mapMutations(["UserInfoChange"]),
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  border: 0 none;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* 定义滚动条样式 */
.menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.home .content .views::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ant-table-pagination.ant-pagination {
  width: 100%;
  text-align: right;
}

.ant-table-pagination.ant-pagination .ant-pagination-total-text {
  float: left;
}

.ant-table td,
.ant-table th {
  white-space: nowrap;
}

.ant-table .operate-icon {
  margin: 0 10px;
}

.ant-form-item {
  justify-content: end;
}

.form-diy-label {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }

  &.required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

/* 地图 */
.amap-marker-label {
  border: 0 none;
  font-size: 16px;
  line-height: 1.5;
  background: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 2px;
}

/* 字体 */
.fw-b {
  font-weight: bold;
}

.fw-n {
  font-weight: normal;
}

/* 边距 */
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.t-c {
  text-align: center;
}

.t-l {
  text-align: left;
}

.t-r {
  text-align: right;
}

/* 相对绝对布局 */
.p-a {
  position: absolute;
}

.p-r {
  position: relative;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

/* 弹性布局 */
.p-flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}
</style>
