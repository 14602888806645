import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () =>
            import('../views/Home.vue'),
        children: [
            {
                path: 'Home',
                name: 'Home',
                meta: {
                    title: '',
                },
                component: () =>
                    import('@src/views/System/HomeTip/index.vue')
            },
            //  用户奶卡
            {
                path: 'userMilkCard',
                name: 'userMilkCard',
                component: {
                    render: h => h("router-view")
                },
                children: [
                    //  用户奶卡
                    {
                        path: 'milkCardList',
                        name: 'milkCardList',
                        meta: {
                            title: '用户奶卡',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/milkCardList/index.vue')
                    },
                    //  邮寄订单
                    {
                        path: 'milkCardMailOrder',
                        name: 'milkCardMailOrder',
                        meta: {
                            title: '邮寄订单',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/milkCardMailOrder/index.vue')
                    },
                    //  核销记录
                    {
                        path: 'milkCardLogisticsOrder',
                        name: 'milkCardLogisticsOrder',
                        meta: {
                            title: '核销记录',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/milkCardLogisticsOrder/index.vue')
                    },
                ]
            },
            //  奶卡定义
            {
                path: 'milkCardConfig',
                name: 'milkCardConfig',
                component: {
                    render: h => h("router-view")
                },
                children: [

                    //  奶卡设置
                    {
                        path: 'milkCardSetting',
                        name: 'milkCardSetting',
                        meta: {
                            title: '奶卡设置',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/milkCardSetting/index.vue')
                    },
                    //  奶卡归类管理
                    {
                        path: 'milkCardCategory',
                        name: 'milkCardCategory',
                        meta: {
                            title: '奶卡归类管理',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/milkCardCategory/index.vue')
                    },
                    //  序列号管理
                    {
                        path: 'serialNumberManagement',
                        name: 'serialNumberManagement',
                        meta: {
                            title: '序列号管理',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/serialNumberManagement/index.vue')
                    },
                ]
            },
            // 财务对账
             {
                path: 'financialReconciliation',
                name: 'financialReconciliation',
                component: {
                    render: h => h("router-view")
                },
                children: [
                    {
                        path: 'financePage',
                        name: 'financePage',
                        meta: {
                            title: '财务对账'
                        },
                        component: () =>
                            import('@src/views/financialReconciliation/financePage/index.vue')
                    }
                ]
            },

            //  商品管理
            {
                path: 'commodity',
                name: 'commodity',
                component: {
                    render: h => h("router-view")
                },
                children: [
                    //  商品管理
                    {
                        path: 'commodityManagement',
                        name: 'commodityManagement',
                        meta: {
                            title: '商品管理',
                        },
                        component: () =>
                            import('@src/views/milkCardManagement/commodityManagement/index.vue')
                    },
                ]
            },
            // 地推管理
            {
                path: 'popularize',
                name: 'popularize',
                component: {
                    render: h => h("router-view")
                },
                children: [
                    //  地推人员
                    {
                        path: 'popularizeUser',
                        name: 'popularizeUser',
                        meta: {
                            title: '地推人员',
                        },
                        component: () =>
                            import('@src/views/popularize/popularizeUser/index.vue')
                    },
                    //  地推记录
                    {
                        path: 'popularizeRecord',
                        name: 'popularizeRecord',
                        meta: {
                            title: '地推记录',
                        },
                        component: () =>
                            import('@src/views/popularize/popularizeRecord/index.vue')
                    },
                ],
            },
            // 系统设置
            {
                path: 'setting',
                name: 'setting',
                component: {
                    render: h => h("router-view")
                },
                children: [
                    //  客服设置
                    {
                        path: 'customerService',
                        name: 'customerService',
                        meta: {
                            title: '客服设置',
                        },
                        component: () =>
                            import('@src/views/settings/customerService/index.vue')
                    },
                    //  限制发货地址
                    {
                        path: 'addressManagement',
                        name: 'addressManagement',
                        meta: {
                            title: '限制发货地址',
                        },
                        component: () =>
                            import('@src/views/settings/addressManagement/index.vue')
                    },
                ],
            }
        ]
    },
    {
        path: 'Login',
        name: 'Login',
        meta: {
            title: '登录',
        },
        component: () =>
            import('@src/views/System/Login.vue')
    },
    {
        path: '*',
        redirect: 'Home'
    }
]

const router = new VueRouter({
    mode: process.env.NODE_ENV == 'development' ? 'hash' : 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title ? `${to.meta.title}-${process.env.VUE_APP_GLOB_TITLE}` : process.env.VUE_APP_GLOB_TITLE;
    next();
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router