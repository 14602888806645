import base from "./base";
import axios from "../utils/axios_http";
// import md5 from "js-md5";

const User = {
  getUserInfo() {
    return axios.get(`${base.User}/querySysUserById`);
  },
  login(params) {
    return axios.post(`${base.User}/userLogin`, {
      username: params.username,
      // password: md5(params.password)
      password: params.password,
    });
  },
  logout() {
    return axios.post(`${base.User}/logout`);
  },
  // 修改密码
  updateUserPassword(params) {
    return axios.post(`${base.User}/updateUserPassword`, params);
  },
};

export default User;
