import base from './base';
import axios from '../utils/axios_http';

const popularize = {
  // 地推人员
  // 地推人员管理-查询接口
  getPopularizeUserList(params) {
    return axios.post(`${base.popularize}/queryPopularizeUserList`, params);
  },
  // 地推人员id逻辑删除地推用户【含批量删除】
  deletePopularizeUserById(params) {
    return axios.get(`${base.popularize}/deletePopularizeUserById`, params);
  },
  // 新增地推人员
  insertPopularizeUser(params) {
    return axios.post(`${base.popularize}/insertPopularizeUser`, params);
  },
  // 修改地推人员
  updatePopularizeUserById(params) {
    return axios.post(`${base.popularize}/updatePopularizeUserById`, params);
  },
  // 根据单个地推人员的用户id下载二维码图片
  downloadByPopularizeId(params) {
    return axios.get(`${base.popularize}/downloadByPopularizeId`, params, { responseType: 'blob' });
    // return `${base.popularize}/downloadByPopularizeId?popularizeId=${params}`;
  },
  // 批量下载地推人员的二维码图片并压缩返回给前端下载
  downPopularizeQrCodeListForZip(params) {
    return axios.post(`${base.popularize}/downPopularizeQrCodeListForZip`, params, { responseType: 'blob' });
  },
  // 个人地推记录
  queryPopularizeRecordDetailByUserId(params) {
    return axios.post(`${base.popularize}/queryPopularizeRecordDetailByUserId`, params);
  },

  // 地推记录管理-查询接口
  getPopularizeRecordList(params) {
    return axios.post(`${base.popularize}/queryPopularizeRecordList`, params);
  },

  // 地推记录管理-导出
  exportPopularizeRecordList(params) {
    return axios.post(`${base.popularize}/exportPopularizeRecordList`, params,{ responseType: 'blob' });
  },
};

export default popularize;