import base from "./base";
import axios from "../utils/axios_http";

const milkCardManagement = {
  // 用户奶卡 搜索
  getMilkCardList(params) {
    return axios.post(
      `${base.milkCardManagement}/queryuserMilkCardList`,
      params
    );
  },
  // 用户奶卡 删除
  deleteMikeCard(params) {
    return axios.get(
      `${base.milkCardManagement}/deleteUserMilkCardById`,
      params
    );
  },

  // 用户奶卡 退款
  milkCardRefund(params) {
    return axios.post(`${base.milkCardRefund}/userMilkCardRefund`, params);
  },

  // 使用记录
  getUseRecord(params) {
    return axios.post(
      `${base.milkCardManagement}/queryUserMilkCardUseRecordById`,
      params
    );
  },
  // 充值记录
  getRecharge(params) {
    return axios.post(
      `${base.milkCardManagement}/queryUserMilkCardChargeRecordById`,
      params
    );
  },

  // 邮寄订单
  getMilkCardMailOrder(params) {
    return axios.post(`${base.order}/queryorderList`, params);
  },
  // 关闭邮寄订单
  closeOrder(params) {
    return axios.get(`${base.order}/closeOrder`, params);
  },
  // 导出
  export(params) {
    return axios.post(`${base.order}/exportOrderForExcel`, params, {
      responseType: "blob",
    });
  },
  // 发货
  deliver(params) {
    return axios.post(`${base.order}/pushOrder`, params);
  },
  // 查询物流公司
  getLogistics(params) {
    return axios.get(`${base.order}/getAllLogisticsTypeEnumArr`, params);
  },
  // 查物流信息
  getLogisticsMessage(params) {
    return axios.get(`${base.order}/queryLogisticsInfoByOrderNum`, params);
  },

  // 核销记录
  getMilkCardLogisticsOrder(params) {
    return axios.post(`${base.verifySell}/queryVerifySellRecordList`, params);
  },
  // 核销导出
  exportVerifySellRecordList(params) {
    return axios.post(`${base.verifySell}/exportVerifySellRecordList`, params, {
      responseType: "blob",
    });
  },
  // PC端-奶卡核销记录删除
  cancelVerifySellRecord(params) {
    return axios.post(`${base.verifySell}/cancelVerifySellRecord`, params);
  },

  // 奶卡图片上传
  uploadImg(params) {
    return axios.post(`${base.uploadImg}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // 奶卡导出
  exportUserMilkCardList(params) {
    return axios.post(
      `${base.milkCardManagement}/exportUserMilkCardList`,
      params,
      { responseType: "blob" }
    );
  },

  // 商品管理
  // 商品-查询接口
  queryCommodityList(params) {
    return axios.post(`${base.commodity}/queryCommodityList`, params);
  },
  // 新增商品
  insertCommodity(params) {
    return axios.post(`${base.commodity}/insertCommodity`, params);
  },
  // 修改商品
  updateCommodityById(params) {
    return axios.post(`${base.commodity}/updateCommodityById`, params);
  },
  // 根据商品id逻辑删除【含批量删除】
  deleteCommodityByIds(params) {
    return axios.get(`${base.commodity}/deleteCommodityByIds`, params);
  },

  // 奶卡设置
  // 奶卡-查询接口
  queryMilkCardList(params) {
    return axios.post(`${base.milk}/queryMilkCardList`, params);
  },
  // 新增奶卡
  insertMilkCard(params) {
    return axios.post(`${base.milk}/insertMilkCard`, params);
  },
  // 修改奶卡
  updateMilkCardById(params) {
    return axios.post(`${base.milk}/updateMilkCardById`, params);
  },
  // 根据奶卡id批量上架/下架
  deleteMilkCardByIds(params) {
    return axios.get(`${base.milk}/deleteMilkCardByIds`, params);
  },

  // 序列号管理
  // 序列号-查询接口【含列表、详情和高级筛选】
  querySerialNumberList(params) {
    return axios.post(`${base.serialNumber}/querySerialNumberList`, params);
  },
  // 序列号导出
  exportSerialNumberForExcel(params) {
    return axios.post(
      `${base.serialNumber}/exportSerialNumberForExcel`,
      params,
      { responseType: "blob" }
    );
  },
  // 根据序列号删除【含批量删除】
  deleteSerialNumber(params) {
    return axios.post(`${base.serialNumber}/deleteSerialNumber`, params);
  },
  // 生成序列号
  generateSerialNumber(params) {
    return axios.post(`${base.serialNumber}/generateSerialNumber`, params);
  },
  // 序列号导出时查询可导出的序列号数量
  countSerialNumberForExcel(params) {
    return axios.get(`${base.serialNumber}/countSerialNumberForExcel`, params);
  },

  // 奶卡类型
  // 查询接口【含列表、详情和高级筛选】
  queryMilkCardTypeList(params) {
    return axios.post(`${base.milkCardType}/queryMilkCardTypeList`, params);
  },
  // 新增奶卡类型
  insertMilkCardType(params) {
    return axios.post(`${base.milkCardType}/insertMilkCardType`, params);
  },
  // 修改奶卡类型
  updateMilkCardTypeById(params) {
    return axios.post(`${base.milkCardType}/updateMilkCardTypeById`, params);
  },
  // 根据奶卡类型id逻辑删除【含批量删除】
  deleteMilkCardTypeByIds(params) {
    return axios.get(`${base.milkCardType}/deleteMilkCardTypeByIds`, params);
  },

  // *******奶卡对账******
  milkCardReconciliation(params) {
    //查询
    return axios.post(`${base.userMilkCardStatistic}/getList`, params);
  },

  milkCardExportList(params) {
    //导出
    return axios.post(`${base.userMilkCardStatistic}/exportList`, params, {
      responseType: "blob",
    });
  },

  // 分销等级
  userGetGradeAll(params) {
    return axios.get(`${base.getGradeAll}/getGradeAll`, params);
  },

  // 奶卡商品分类管理
  // 删除一级分类
  deleteGoodPrimaryCategory(params) {
    return axios.post(`${base.goodCategory}/deleteGoodPrimaryCategory`, params);
  },
  // 删除二级分类
  deleteGoodSecondCategory(params) {
    return axios.post(`${base.goodCategory}/deleteGoodSecondCategory`, params);
  },
  // 获取分页一级分类列表
  getGoodPrimaryCategoryPage(params) {
    return axios.get(`${base.goodCategory}/getGoodPrimaryCategoryPage`, {
      params,
    });
  },
  // 获取一级分类详情
  getGoodPrimaryCategoryVO(params) {
    return axios.get(`${base.goodCategory}/getGoodPrimaryCategoryVO`, {
      params,
    });
  },
  // 奶卡商品编辑-获取一级分类列表不分页
  getGoodPrimaryCategoryVOList(params) {
    return axios.get(`${base.goodCategory}/getGoodPrimaryCategoryVOList`, {
      params,
    });
  },
  // 获取二级分类详情
  getGoodSecondCategoryVO(params) {
    return axios.get(`${base.goodCategory}/getGoodSecondCategoryVO`, {
      params,
    });
  },
  // 奶卡商品编辑-获取指定一级分类下的二级分类列表
  getSecondCategoryListWithFirstId(params) {
    return axios.get(`${base.goodCategory}/getSecondCategoryListWithFirstId`, {
      params,
    });
  },
  // 编辑保存奶卡商品一级分类信息
  saveGoodPrimaryCategory(params) {
    return axios.post(`${base.goodCategory}/saveGoodPrimaryCategory`, params);
  },
  // 编辑保存二级分类
  saveGoodSecondCategory(params) {
    return axios.post(`${base.goodCategory}/saveGoodSecondCategory`, params);
  },
  // 获取店铺归属城市列表
  getCityList(params) {
    return axios.get(`${base.milk}/getCityList`, { params });
  },
  // 获取店铺归属城市列表
  getMilkCardQrCode(params) {
    return axios.get(`${base.getMilkCardQrCode}/getMilkCardQrCode`, { params });
  },
  // 用户过期奶卡延期
  delayUserMilkCard(params) {
    return axios.post(`${base.milk}/delayUserMilkCard`, params);
  },
  // 门店一二三级分类
  getStores(params) {
    return axios.get(`${base.payment}/getStoreCategoryTreeWithStore`, params);
  },
};
export default milkCardManagement;
