import Users from "./User.js";
import milkCardManagements from "./milkCardManagement.js";
import Setting from "./Settings.js";
import Popularize from "./popularize.js";
import home from "./Home.js";
import cashier from "./Cashier.js";

export const User = Users;
export const milkCardManagement = milkCardManagements;
export const Settings = Setting;
export const popularize = Popularize;
export const Home = home;
export const Cashier = cashier;
